@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    font-family: "Poppins", sans-serif;
  }
}
* {
  scroll-behavior: smooth;
}
html,
body {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h5 span {
  font-family: "Anton", sans-serif !important;
}
